import { authenticationService } from "../services/authenticationService";
import Avatar from "@material-ui/core/Avatar";
import ChatConfiguration from "../config/ChatConfig";
import Divider from "@material-ui/core/Divider";
import getInitialsFromName from "../utilities/common";
import LanguageIcon from "@material-ui/icons/Language";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemAvatar from "@material-ui/core/ListItemAvatar";
import ListItemText from "@material-ui/core/ListItemText";
import { nanoid } from "nanoid";
import React, {
  Dispatch,
  SetStateAction,
  useContext,
  useEffect,
  useState,
} from "react";
import socketIOClient from "socket.io-client";
import useChatStore from "../store/chatStore";
import { useGetConversations } from "../services/chatService";

const Conversations = (props: {
  setScope: Dispatch<SetStateAction<string>>;
  setUser: any;
}) => {
  const [conversations, setConversations] = useState([]);
  const [newConversation, setNewConversation] = useState(null);
  const getConversations = useGetConversations();

  const globalChatKey: number = -1;
  const chatSelectedId = useChatStore<number>(
    (state) => state.chatSelectedId as number
  );
  const setChatSelectedId = useChatStore<(chatSelectedId: number) => any>(
    (state) => state.setChatSelectedId as (chatSelectedId: number) => any
  );

  const chatConfiguration = useContext(ChatConfiguration);

  const httpsPort = useChatStore<string>((state) => state.httpsPort as string);

  // Returns The Recipient Name That Does Not Belong To The Current User
  const handleRecipient = (recipients: any) => {
    for (let i = 0; i < recipients.length; i++) {
      if (
        recipients[i].username !==
        authenticationService.currentUserValue.username
      ) {
        return recipients[i];
      }
    }
    return null;
  };

  useEffect(() => {
    getConversations().then((res) => setConversations(res));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [newConversation]);

  useEffect(() => {
    let socket = socketIOClient(chatConfiguration.chatServerUrl + httpsPort);
    socket.on("messages", (data: any) => setNewConversation(data));

    return () => {
      socket.removeListener("messages");
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <List
      className="flex flex-col items-start h-full w-full"
      style={{ paddingTop: "0", paddingBottom: "0" }}
    >
      <ListItem
        key={globalChatKey}
        onClick={() => {
          props.setScope(chatConfiguration.chatGlobalName);
          setChatSelectedId(globalChatKey);
        }}
        className={`cursor-pointer ${
          chatSelectedId === globalChatKey ? "bg-light-blue" : "bg-black"
        }`}
      >
        <ListItemAvatar className="py-1">
          <Avatar
            style={{
              backgroundColor:
                chatSelectedId === globalChatKey ? "#0000CD" : "#C0C0C0",
            }}
          >
            <LanguageIcon />
          </Avatar>
        </ListItemAvatar>
        <ListItemText
          primary={chatConfiguration.chatGlobalName}
          primaryTypographyProps={{
            style: {
              fontWeight: "bold",
              fontSize: "small",
              color: chatSelectedId === globalChatKey ? "#0000CD" : "black",
            },
          }}
        />
      </ListItem>
      <Divider key={nanoid()} className="divide-solid w-full" />

      {conversations &&
      conversations !== undefined &&
      conversations.length > 0 ? (
        <React.Fragment key={nanoid()}>
          {conversations.map((c: any) => (
            <React.Fragment key={nanoid()}>
              <ListItem
                key={c._id}
                onClick={() => {
                  props.setUser(handleRecipient(c.recipientObj));
                  props.setScope(handleRecipient(c.recipientObj).name);
                  setChatSelectedId(handleRecipient(c.recipientObj)._id);
                }}
                className={`cursor-pointer ${
                  chatSelectedId === handleRecipient(c.recipientObj)._id
                    ? "bg-light-blue"
                    : "bg-black"
                }`}
              >
                <ListItemAvatar>
                  <Avatar
                    style={{
                      backgroundColor:
                        chatSelectedId === handleRecipient(c.recipientObj)._id
                          ? "#0000CD"
                          : "#C0C0C0",
                    }}
                  >
                    {getInitialsFromName(handleRecipient(c.recipientObj).name)}
                  </Avatar>
                </ListItemAvatar>
                <ListItemText
                  primary={handleRecipient(c.recipientObj).name}
                  primaryTypographyProps={{
                    style: {
                      fontWeight: "bold",
                      fontSize: "small",
                      color:
                        chatSelectedId === handleRecipient(c.recipientObj)._id
                          ? "#0000CD"
                          : "black",
                    },
                  }}
                  secondary={<React.Fragment>{c.lastMessage}</React.Fragment>}
                  secondaryTypographyProps={{
                    style: {
                      fontWeight: "normal",
                      fontSize: "small",
                    },
                  }}
                />
              </ListItem>
              <Divider key={nanoid()} className="divide-solid w-full" />
            </React.Fragment>
          ))}
        </React.Fragment>
      ) : null}
    </List>
  );
};

export default Conversations;
