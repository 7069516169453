import { BehaviorSubject } from "rxjs";
import ChatConfiguration from "../config/ChatConfig";
import useChatStore from "../store/chatStore";
import { useContext } from "react";
import useHandleResponse from "../utilities/handle-response";
import { useSnackbar } from "notistack";

const currentUserSubject = new BehaviorSubject(
  JSON.parse(localStorage.getItem("currentUser") || "{}")
);

export const authenticationService = {
  logout,
  currentUser: currentUserSubject.asObservable(),
  get currentUserValue() {
    return currentUserSubject.value;
  },
};

export function useLogin() {
  const { enqueueSnackbar } = useSnackbar();
  const handleResponse = useHandleResponse();

  const chatConfiguration = useContext(ChatConfiguration);

  const httpsPort = useChatStore<string>((state) => state.httpsPort as string);

  const login = (username: string, password: string) => {
    const requestOptions = {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({ username, password }),
    };

    return fetch(
      `${chatConfiguration.chatServerUrl + httpsPort}/api/users/login`,
      requestOptions
    )
      .then(handleResponse)
      .then((user) => {
        localStorage.setItem("currentUser", JSON.stringify(user));
        currentUserSubject.next(user);
        return user;
      })
      .catch(function () {
        enqueueSnackbar("Failed To Login", {
          variant: "error",
        });
      });
  };

  return login;
}

export function useRegister() {
  const { enqueueSnackbar } = useSnackbar();
  const handleResponse = useHandleResponse();

  const chatConfiguration = useContext(ChatConfiguration);

  const httpsPort = useChatStore<string>((state) => state.httpsPort as string);

  const register = (
    name: string,
    username: string,
    password: string,
    password2: string
  ) => {
    const requestOptions = {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({ name, username, password, password2 }),
    };

    return fetch(
      `${chatConfiguration.chatServerUrl + httpsPort}/api/users/register`,
      requestOptions
    )
      .then(handleResponse)
      .then((user) => {
        localStorage.setItem("currentUser", JSON.stringify(user));
        currentUserSubject.next(user);

        return user;
      })
      .catch(function (response) {
        if (response) {
          enqueueSnackbar("Failed To Register", {
            variant: "error",
          });
        } else {
          enqueueSnackbar("Failed To Register", {
            variant: "error",
          });
        }
      });
  };

  return register;
}

function logout() {
  localStorage.removeItem("currentUser");
  currentUserSubject.next(null);
}
