import create from "zustand";

const useChatStore = create((set) => ({
  chatSelectedId: -1,
  setChatSelectedId: (chatSelectedId: number) =>
    set(() => ({ chatSelectedId: chatSelectedId })),
  userLoggedIn: false,
  setUserLoggedIn: (userLoggedIn: boolean) =>
    set(() => ({ userLoggedIn: userLoggedIn })),
  httpsPort: ":443",
}));

export default useChatStore;
