import ChatView from "./components/chat/ChatView";
import { SnackbarProvider } from "notistack";

function App() {
  return (
    <div className="flex w-full h-screen items-center justify-center">
      <SnackbarProvider maxSnack={3} autoHideDuration={3000}>
        <ChatView />
      </SnackbarProvider>
    </div>
  );
}

export default App;
