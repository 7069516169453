import useHandleResponse from "../utilities/handle-response";
import authHeader from "../utilities/auth-header";
import ChatConfiguration from "../config/ChatConfig";
import useChatStore from "../store/chatStore";
import { useContext } from "react";
import { useSnackbar } from "notistack";

export function useGetUsers() {
  const { enqueueSnackbar } = useSnackbar();
  const handleResponse = useHandleResponse();
  const requestOptions = {
    method: "GET",
    headers: authHeader(),
  };

  const chatConfiguration = useContext(ChatConfiguration);

  const httpsPort = useChatStore<string>((state) => state.httpsPort as string);

  const getUsers = () => {
    return fetch(
      `${chatConfiguration.chatServerUrl + httpsPort}/api/users`,
      requestOptions as RequestInit
    )
      .then(handleResponse)
      .catch(() =>
        enqueueSnackbar("Could NOT Load Users", {
          variant: "error",
        })
      );
  };

  return getUsers;
}
