import ChatBox from "../layouts/ChatBox";
import ChatConfiguration from "../config/ChatConfig";
import Conversations from "../layouts/Conversations";
import { useContext, useState } from "react";
import Users from "../layouts/Users";

const Chat = () => {
  const chatConfiguration = useContext(ChatConfiguration);
  const [scope, setScope] = useState(chatConfiguration.chatGlobalName);
  const [tab, setTab] = useState(0);
  const [user, setUser] = useState(null);

  return (
    <div className="grid w-full grid-rows-8 grid-cols-6">
      <div
        className={`flex row-span-1 col-span-1 items-center justify-center cursor-pointer bg-light-gray ${
          tab === 0
            ? "font-bold text-medium-blue border-b-2 border-medium-blue"
            : "border-b border-r text-medium-gray border-medium-gray"
        }`}
        onClick={() => {
          if (tab !== 0) {
            setTab(0);
          }
        }}
      >
        <span>Chats</span>
      </div>
      <div
        className={`flex row-span-1 col-span-1 items-center justify-center cursor-pointer bg-light-gray ${
          tab === 1
            ? "font-bold text-medium-blue border-b-2 border-medium-blue"
            : "border-b border-l text-medium-gray border-medium-gray"
        }`}
        onClick={() => {
          if (tab !== 1) {
            setTab(1);
          }
        }}
      >
        <span>Users</span>
      </div>
      <div className="flex row-span-8 col-span-4 items-center justify-center border-b border-l font-bold text-black border-medium-gray">
        <ChatBox scope={scope} user={user!} />
      </div>
      <div className="flex row-span-7 col-span-2 items-center justify-center bg-light-gray">
        {tab === 0 && <Conversations setUser={setUser} setScope={setScope} />}
        {tab === 1 && <Users setUser={setUser} setScope={setScope} />}
      </div>
    </div>
  );
};

export default Chat;
