import { authenticationService } from "../services/authenticationService";
import Chat from "../views/Chat";
import Login from "../views/Login";
import React, { useEffect, useState } from "react";
import Register from "../views/Register";
import useChatStore from "../store/chatStore";

const ChatView = () => {
  const [toggle, setToggle] = useState(false);

  const handleClick = () => {
    setToggle(!toggle);
  };

  const userLoggedIn = useChatStore<boolean>(
    (state) => state.userLoggedIn as boolean
  );

  const setUserLoggedIn = useChatStore<(userLoggedIn: boolean) => any>(
    (state) => state.setUserLoggedIn as (userLoggedIn: boolean) => any
  );

  useEffect(() => {
    if (!isEmpty(authenticationService.currentUserValue)) {
      setUserLoggedIn(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <React.Fragment>
      <div className="flex w-1/2 h-1/2 border-2 border-gray-400">
        {!userLoggedIn ? (
          toggle !== true ? (
            <Login handleClick={handleClick}></Login>
          ) : (
            <Register handleClick={handleClick}></Register>
          )
        ) : (
          <Chat />
        )}
      </div>
    </React.Fragment>
  );
};

function isEmpty(obj: { hasOwnProperty: (arg0: string) => any }) {
  for (var key in obj) {
    if (obj.hasOwnProperty(key)) return false;
  }
  return true;
}

export default ChatView;
