import authHeader from "../utilities/auth-header";
import ChatConfiguration from "../config/ChatConfig";
import useChatStore from "../store/chatStore";
import { useContext } from "react";
import useHandleResponse from "../utilities/handle-response";
import { useSnackbar } from "notistack";

// Receive Global Messages
export function useGetGlobalMessages() {
  const { enqueueSnackbar } = useSnackbar();
  const handleResponse = useHandleResponse();
  const requestOptions = {
    method: "GET",
    headers: authHeader(),
  };

  const chatConfiguration = useContext(ChatConfiguration);

  const httpsPort = useChatStore<string>((state) => state.httpsPort as string);

  const getGlobalMessages = () => {
    return fetch(
      `${chatConfiguration.chatServerUrl + httpsPort}/api/messages/global`,
      requestOptions as RequestInit
    )
      .then(handleResponse)
      .catch(() =>
        enqueueSnackbar(`Could NOT Load ${chatConfiguration.chatGlobalName}`, {
          variant: "error",
        })
      );
  };

  return getGlobalMessages;
}

// Send A Global Message
export function useSendGlobalMessage() {
  const { enqueueSnackbar } = useSnackbar();
  const handleResponse = useHandleResponse();

  const chatConfiguration = useContext(ChatConfiguration);

  const httpsPort = useChatStore<string>((state) => state.httpsPort as string);

  const sendGlobalMessage = (body: any) => {
    const requestOptions = {
      method: "POST",
      headers: authHeader(),
      body: JSON.stringify({ body: body, global: true }),
    };

    return fetch(
      `${chatConfiguration.chatServerUrl + httpsPort}/api/messages/global`,
      requestOptions as RequestInit
    )
      .then(handleResponse)
      .catch((err) => {
        console.log(err);
        enqueueSnackbar("Could NOT Send Message", {
          variant: "error",
        });
      });
  };

  return sendGlobalMessage;
}

// Get List Of Users Conversations
export function useGetConversations() {
  const { enqueueSnackbar } = useSnackbar();
  const handleResponse = useHandleResponse();
  const requestOptions = {
    method: "GET",
    headers: authHeader(),
  };

  const chatConfiguration = useContext(ChatConfiguration);

  const httpsPort = useChatStore<string>((state) => state.httpsPort as string);

  const getConversations = () => {
    return fetch(
      `${
        chatConfiguration.chatServerUrl + httpsPort
      }/api/messages/conversations`,
      requestOptions as RequestInit
    )
      .then(handleResponse)
      .catch(() =>
        enqueueSnackbar("Could NOT Load Private Chats", {
          variant: "error",
        })
      );
  };

  return getConversations;
}

// Get Conversation Messages Based On To And From ID's
export function useGetConversationMessages() {
  const { enqueueSnackbar } = useSnackbar();
  const handleResponse = useHandleResponse();
  const requestOptions = {
    method: "GET",
    headers: authHeader(),
  };

  const chatConfiguration = useContext(ChatConfiguration);

  const httpsPort = useChatStore<string>((state) => state.httpsPort as string);

  const getConversationMessages = (id: any) => {
    return fetch(
      `${
        chatConfiguration.chatServerUrl + httpsPort
      }/api/messages/conversations/query?userId=${id}`,
      requestOptions as RequestInit
    )
      .then(handleResponse)
      .catch(() =>
        enqueueSnackbar("Could NOT Load Chat Messages", {
          variant: "error",
        })
      );
  };

  return getConversationMessages;
}

export function useSendConversationMessage() {
  const { enqueueSnackbar } = useSnackbar();
  const handleResponse = useHandleResponse();

  const chatConfiguration = useContext(ChatConfiguration);

  const httpsPort = useChatStore<string>((state) => state.httpsPort as string);

  const sendConversationMessage = (id: any, body: any) => {
    const requestOptions = {
      method: "POST",
      headers: authHeader(),
      body: JSON.stringify({ to: id, body: body }),
    };

    return fetch(
      `${chatConfiguration.chatServerUrl + httpsPort}/api/messages/`,
      requestOptions as RequestInit
    )
      .then(handleResponse)
      .catch((err) => {
        console.log(err);
        enqueueSnackbar("Could NOT Send Message", {
          variant: "error",
        });
      });
  };

  return sendConversationMessage;
}
