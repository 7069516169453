import Avatar from "@material-ui/core/Avatar";
import ChatConfiguration from "../config/ChatConfig";
import Divider from "@material-ui/core/Divider";
import getInitialsFromName from "../utilities/common";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemAvatar from "@material-ui/core/ListItemAvatar";
import ListItemText from "@material-ui/core/ListItemText";
import { nanoid } from "nanoid";
import React, {
  Dispatch,
  SetStateAction,
  useContext,
  useEffect,
  useState,
} from "react";
import socketIOClient from "socket.io-client";
import useChatStore from "../store/chatStore";
import { useGetUsers } from "../services/userService";

const Users = (props: {
  setUser: (arg0: any) => void;
  setScope: Dispatch<SetStateAction<string>>;
}) => {
  const [users, setUsers] = useState([]);
  const [newUser, setNewUser] = useState(null);
  const getUsers = useGetUsers();

  const chatSelectedId = useChatStore<number>(
    (state) => state.chatSelectedId as number
  );
  const setChatSelectedId = useChatStore<(chatSelectedId: number) => any>(
    (state) => state.setChatSelectedId as (chatSelectedId: number) => any
  );

  const chatConfiguration = useContext(ChatConfiguration);

  const httpsPort = useChatStore<string>((state) => state.httpsPort as string);

  useEffect(() => {
    getUsers().then((res) => setUsers(res));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [newUser]);

  useEffect(() => {
    const socket = socketIOClient(chatConfiguration.chatServerUrl + httpsPort);
    socket.on("users", (data: any) => {
      setNewUser(data);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <List
      className="flex flex-col items-start h-full w-full"
      style={{ paddingTop: "0", paddingBottom: "0" }}
    >
      {users && users !== undefined && users.length > 0 ? (
        <React.Fragment key={nanoid()}>
          {users.map((u: any) => (
            <React.Fragment key={nanoid()}>
              <ListItem
                key={u._id}
                onClick={() => {
                  props.setUser(u);
                  props.setScope(u.name);
                  setChatSelectedId(u._id);
                }}
                className={`cursor-pointer ${
                  chatSelectedId === u._id ? "bg-light-blue" : "bg-black"
                }`}
              >
                <ListItemAvatar className="py-1">
                  <Avatar
                    style={{
                      backgroundColor:
                        chatSelectedId === u._id ? "#0000CD" : "#C0C0C0",
                    }}
                  >
                    {getInitialsFromName(u.name)}
                  </Avatar>
                </ListItemAvatar>
                <ListItemText
                  primary={u.name}
                  primaryTypographyProps={{
                    style: {
                      fontWeight: "bold",
                      fontSize: "small",
                      color: chatSelectedId === u._id ? "#0000CD" : "black",
                    },
                  }}
                />
              </ListItem>
              <Divider key={nanoid()} className="divide-solid w-full" />
            </React.Fragment>
          ))}
        </React.Fragment>
      ) : null}
    </List>
  );
};

export default Users;
