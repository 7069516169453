import React from "react";

const ChatConfiguration = React.createContext({
  // Chat Host Server IP + Port (Must Be Different For Each Virtual Space => Independent Chats)
  chatServerUrl: "https://chat-server-internal.imascono.com", // For Local Testing Please Use: "http://localhost"
  // Name Shown On Client Side Chat For General Channel (Commonly Named: Global Chat)
  chatGlobalName: "Global Chat",
});

export default ChatConfiguration;
