import * as Yup from "yup";
import Button from "@material-ui/core/Button";
import { Formik } from "formik";
import { Link } from "@material-ui/core";
import logo_imascono_2020 from "../../assets/images/Logo_Imascono_2020.png";
import TextField from "@material-ui/core/TextField";
import useChatStore from "../store/chatStore";
import { useLogin } from "../services/authenticationService";

const Login = (props: { handleClick: (arg0: string) => any }) => {
  const login = useLogin();

  const setUserLoggedIn = useChatStore<(userLoggedIn: boolean) => any>(
    (state) => state.setUserLoggedIn as (userLoggedIn: boolean) => any
  );

  return (
    <div className="grid w-full grid-rows-6 grid-cols-1 gap-4">
      <div className="flex row-span-2 col-span-1 items-center justify-center">
        <img
          className=""
          src={logo_imascono_2020}
          alt="Logo Imascono 2020"
          style={{
            height: "75%",
            width: "75%",
            margin: "auto",
            objectFit: "contain",
          }}
        ></img>
      </div>
      <div className="flex row-span-4 col-span-1 items-center justify-center">
        <Formik
          initialValues={{
            username: "",
            password: "",
          }}
          validationSchema={Yup.object().shape({
            username: Yup.string()
              .required("Username is required")
              .max(40, "Username is too long"),
            password: Yup.string()
              .required("Password is required")
              .max(100, "Password is too long")
              .min(6, "Password too short"),
          })}
          onSubmit={({ username, password }, { setStatus, setSubmitting }) => {
            setStatus();
            login(username, password).then(
              (user) => {
                if (user !== undefined) {
                  setUserLoggedIn(true);
                }
              },
              (error) => {
                setSubmitting(false);
                setStatus(error);
              }
            );
          }}
        >
          {({ handleSubmit, handleChange, values, touched, errors }) => (
            <form
              className="grid h-full w-5/6 sm:w-4/6 md:w-1/2 lg:w-1/3"
              onSubmit={handleSubmit}
            >
              <TextField
                className=""
                id="username"
                name="username"
                label="Username"
                fullWidth={true}
                variant="outlined"
                // margin="normal"
                required={true}
                helperText={touched.username ? errors.username : ""}
                error={touched.username && Boolean(errors.username)}
                value={values.username}
                onChange={handleChange}
                size="small"
              />
              <TextField
                id="password"
                name="password"
                label="Password"
                fullWidth={true}
                variant="outlined"
                // margin="normal"
                required={true}
                helperText={touched.password ? errors.password : ""}
                error={touched.password && Boolean(errors.password)}
                value={values.password}
                onChange={handleChange}
                type="password"
                size="small"
              />
              <Button
                type="submit"
                fullWidth={true}
                variant="contained"
                color="primary"
                style={{ height: "50%" }}
              >
                Login
              </Button>
              <Link
                className="flex items-start justify-end"
                color="secondary"
                onClick={() => props.handleClick("register")}
                href="#"
              >
                Don't have an account?
              </Link>
            </form>
          )}
        </Formik>
      </div>
    </div>
  );
};

export default Login;
