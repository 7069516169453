import { authenticationService } from "../services/authenticationService";
import Avatar from "@material-ui/core/Avatar";
import ChatConfiguration from "../config/ChatConfig";
import ExitToApp from "@material-ui/icons/ExitToApp";
import getInitialsFromName from "../utilities/common";
import IconButton from "@material-ui/core/IconButton";
import ListItem from "@material-ui/core/ListItem";
import ListItemAvatar from "@material-ui/core/ListItemAvatar";
import ListItemText from "@material-ui/core/ListItemText";
import Moment from "react-moment";
import React, { useContext, useEffect, useRef, useState } from "react";
import ScrollableFeed from "react-scrollable-feed";
import SendIcon from "@material-ui/icons/Send";
import socketIOClient from "socket.io-client";
import TextField from "@material-ui/core/TextField";
import useChatStore from "../store/chatStore";
import {
  useGetConversationMessages,
  useGetGlobalMessages,
  useSendConversationMessage,
  useSendGlobalMessage,
} from "../services/chatService";

const ChatBox = (props: { scope: string; user: { _id: any } }): JSX.Element => {
  const [currentUserId] = useState(
    authenticationService.currentUserValue.userId
  );
  const [newMessage, setNewMessage] = useState("");
  const [messages, setMessages] = useState([]);
  const [lastMessage, setLastMessage] = useState(null);

  const getGlobalMessages = useGetGlobalMessages();
  const sendGlobalMessage = useSendGlobalMessage();
  const getConversationMessages = useGetConversationMessages();
  const sendConversationMessage = useSendConversationMessage();

  let chatBottom = useRef<null | HTMLDivElement>(null);

  const chatConfiguration = useContext(ChatConfiguration);

  const setUserLoggedIn = useChatStore<(userLoggedIn: boolean) => any>(
    (state) => state.setUserLoggedIn as (userLoggedIn: boolean) => any
  );

  const httpsPort = useChatStore<string>((state) => state.httpsPort as string);

  useEffect(() => {
    reloadMessages();
    scrollToBottom();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [lastMessage, props.scope]);

  useEffect(() => {
    const socket = socketIOClient(chatConfiguration.chatServerUrl + httpsPort);
    socket.on("messages", (data: any) => setLastMessage(data));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const reloadMessages = () => {
    if (props.scope === chatConfiguration.chatGlobalName) {
      getGlobalMessages().then((res) => {
        setMessages(res);
      });
    } else if (props.scope !== null) {
      getConversationMessages(props.user._id).then((res) => setMessages(res));
    } else {
      setMessages([]);
    }
  };

  const scrollToBottom = () => {
    if (chatBottom !== null && chatBottom.current !== null) {
      chatBottom.current.scrollIntoView({ behavior: "smooth" });
    }
  };

  useEffect(() => scrollToBottom, [messages]);

  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    if (newMessage.length > 0) {
      if (props.scope === chatConfiguration.chatGlobalName) {
        sendGlobalMessage(newMessage).then(() => {
          setNewMessage("");
        });
      } else {
        sendConversationMessage(props.user._id, newMessage).then((res) => {
          setNewMessage("");
        });
      }
    }
  };

  return (
    <div className="grid w-full h-full grid-rows-8 grid-cols-8">
      <div className="flex row-span-1 col-span-7 items-center justify-center border-b border-medium-gray font-normal">
        <span>
          {<b>{props.scope}</b>}
          {props.scope === chatConfiguration.chatGlobalName
            ? " (Public Channel)"
            : " (Private Conversation)"}
        </span>
      </div>
      <div className="flex row-span-1 col-span-1 items-center justify-center border-b border-l border-medium-gray font-normal bg-light-gray">
        <Avatar
          style={{
            backgroundColor: "transparent",
          }}
          className="cursor-pointer"
          onClick={() => {
            authenticationService.logout();
            setUserLoggedIn(false);
          }}
        >
          <ExitToApp
            style={{
              fill: "black",
            }}
          />
        </Avatar>
      </div>
      <div className="flex row-span-6 col-span-8 items-end max-h-full">
        {messages && messages !== undefined && messages.length > 0 ? (
          <ScrollableFeed className="w-full">
            {messages.map((m: any) => (
              <ListItem
                className={`${
                  m.fromObj[0]._id === currentUserId ? "flex-row-reverse" : ""
                }`}
                key={m._id}
                alignItems="flex-start"
              >
                <ListItemAvatar
                  style={{
                    minWidth: "0",
                  }}
                >
                  <Avatar>{getInitialsFromName(m.fromObj[0].name)}</Avatar>
                </ListItemAvatar>
                &nbsp; &nbsp; &nbsp;
                <div className="flex flex-row w-full border rounded-lg border-medium-gray p-2">
                  <ListItemText
                    className=""
                    primary={m.fromObj[0] && m.fromObj[0].name}
                    primaryTypographyProps={{
                      style: { fontWeight: "bold", fontSize: "small" },
                    }}
                    secondary={<React.Fragment>{m.body}</React.Fragment>}
                    secondaryTypographyProps={{
                      style: { fontWeight: "normal", fontSize: "small" },
                    }}
                  />
                  <span className="text-xs font-normal">
                    <Moment format="DD/MM/YYYY - HH:mm:ss">
                      {new Date(+m.date).toUTCString()}
                    </Moment>
                  </span>
                </div>
              </ListItem>
            ))}
            <div ref={chatBottom} />
          </ScrollableFeed>
        ) : null}
        <div ref={chatBottom} />
      </div>
      <div className="flex row-span-6 col-span-8 items-center justify-center border-t border-medium-gray">
        <form onSubmit={handleSubmit} className="flex flex-row w-full pl-3">
          <TextField
            id="message"
            label="Message"
            variant="outlined"
            margin="dense"
            fullWidth={true}
            value={newMessage}
            onChange={(e) => setNewMessage(e.target.value)}
            size="small"
          />
          <IconButton type="submit">
            <SendIcon />
          </IconButton>
        </form>
      </div>
    </div>
  );
};

export default ChatBox;
